import { Outlet } from "@remix-run/react";
import logo from "~/images/logo.webp";

export default function Index() {
  return (
    <>
      <header>
        <img
          src={logo}
          alt="Buy Michigan Now"
          width="81"
          height="65"
          className="m-4"
        />
        <div className="bg-cover bg-center bg-[url('~/images/banner.webp')] py-8 px-2 text-center text-white">
          <h1 className="text-3xl font-bold mb-2 lg:text-5xl">
            BUY MICHIGAN NOW FESTIVAL
          </h1>
        </div>
      </header>
      <main className="py-10 px-4 max-w-xl mx-auto w-full sm:p-10">
        <Outlet />
      </main>
    </>
  );
}
